.searchElementSOP {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -4rem;
  /* margin-right: 2rem; */
  margin-bottom: 1rem;
  width: 16rem;
}

.searchControlSOP {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
}

.searchBoxSOP {
  height: 0.75rem;
}

.searchBoxSOP input {
  font-family: var(--regular);
  /* background-color: inherit; */
  /* border: 1px solid var(--secondary); */
  height: 100%;
  width: 100%;
  padding-bottom: 0.35rem;
  padding-left: 0.25rem;
  font-size: 1rem;
  z-index: 100;
}

.searchBoxSOP label {
  font-family: var(--semiBold);
  position: absolute;
  /* top: 0.35rem; */
  /* left: 0.5rem; */
  width: 100%;
  opacity: 0.5;
  z-index: 2;
}

.searchBoxSOP label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.searchContentSOP {
  /* position: absolute; */
  /* transition: all 0.3s ease; */
  /* font-size: 1rem; */
  /* opacity: 0.5; */
  cursor: pointer;
  /* width: 1%; */
  /* border: 1px solid green; */
}
.searchInputContainerSOP {
  position: relative;
  width: 100%;
  /* height: 1.25rem; */
  /* border: 1px solid gray; */
}

.searchBoxSOP input:focus + .searchLabelSOP .searchContent,
.searchBoxSOP input:valid + .searchLabelSOP .searchContent,
.searchInputContainer:focus-within .searchLabelSOP .searchContent {
  transform: translateY(-100%);
  /* font-size: 1rem; */
  opacity: 0.5;
}

.searchActionSOP {
  cursor: pointer;
  margin-left: 3rem;
}

.searchActionSOP img {
  padding: 0.5rem;
  border-radius: 50%;
  /* background-color: var(--light-gray); */
  width: 2rem;
}

.searchActionSOP img:hover {
  padding: 0.425rem;
  /* border: 0.075rem inset var(--secondary); */
}
