.griContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);
}

.gridItemHeader {
  display: flex;
}

.flexContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  line-height: 1.5rem;
}

.flexItemHeader {
  text-align: center;
  /* padding-left: 0.35rem;
  margin-bottom: 0.75rem; */
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.15rem;
}

.gridItemRow {
  display: flex;
}

.flexContainerRow {
  width: 100%;
}
.flexItemRow {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: var(--regular);
  font-size: 1rem;
  line-height: 2.75rem;
  justify-content: space-between;
  width: 100%;
}
.flexItemRow:hover,
.flexItemRowSelected:hover {
  background-color: var(--light-gray);
  cursor: pointer;
  width: 100%;
}

.flexItemRowSelected {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  font-family: var(--regular);
  font-size: 1rem;
  background-color: var(--light-gray);
  border: 1px solid var(--primary);
  line-height: 2.75rem;
}

.flexItemDetail {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
