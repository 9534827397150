* {
  margin: 0;
}

@font-face {
  font-family: "PorscheNext-Regular";
  src: url("./ui/Assets/Fonts/PorscheNext-Regular.otf");
}

@font-face {
  font-family: "PorscheNext-Thin";
  src: url("./ui/Assets/Fonts/PorscheNext-Thin.otf");
}

@font-face {
  font-family: "PorscheNext-SemiBold";
  src: url("./ui/Assets/Fonts/PorscheNext-SemiBold.otf");
}

@font-face {
  font-family: "PorscheNext-Bold";
  src: url("./ui/Assets/Fonts/PorscheNext-Bold.otf");
}

body {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  cursor: default;
  /* Disable the default cursor when hovering over text */
}

:root {
  /*---------------------------------------------------------------------------------------------------*/
  /*-----------------------------------------COLORS----------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------------*/
  --primary: rgba(213, 0, 28, 0.958);
  --primary-hover: rgb(152, 0, 20);
  --secondary: #323639;
  --secondary-hover: #151718;

  --error: #e00000;
  --success: #018a16;

  --dark-gray: #96989a;
  --light-gray: #ebebeb;

  --divider-light: #e3e4e5;
  /*---------------------------------------------------------------------------------------------------*/
  /*--------------------------------------FONT FAMILY--------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------------*/
  --regular: "PorscheNext-Regular";
  --semiBold: "PorscheNext-SemiBold";
  --thin: "PorscheNext-Thin";
  --bold: "PorscheNext-Bold";
}

/*---------------------------------------------------------------------------------------------------*/
/*--------------------------------------PREDEFINED ELEMENTS-----DROPDOWN MENU -----------------------*/
/*---------------------------------------------------------------------------------------------------*/

.rdn-control-placeholder {
  font-family: var(--semiBold);
  font-size: 1.35rem;
  color: var(--secondary);
  height: 2.55rem;
  opacity: 0.8;
  border: 1px solid var(--secondary);
}

.rdn-control-arrow {
  display: none;
  height: 0;
  border: none !important;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app {
  width: 100%;
  max-width: 1360px;
  height: 100vh;
}

/* @media only screen and (min-width: 1600px) {
  .app-container {
    margin-left: 15rem;
  }
}
@media only screen and (min-width: 2500px) {
  .app-container {
    margin-left: 30rem;
  }
} */
