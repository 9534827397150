.lastResultBtn {
  border: 1px solid gray;
  width: 7.5rem;
  height: 1.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: var(--light-gray);
  cursor: pointer;
  padding: 0.25rem;
}

.lastResultBtn:hover {
  border: 1px solid gray;
  width: 7.5rem;
  height: 1.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-gray);
  cursor: pointer;
  padding: 0.25rem;
}

.lastResultBtnText {
  width: 100%;
  text-align: center;
  padding: 0.25rem;
  height: 100%;
  font-size: 1rem;
  font-family: var(--semiBold);
  color: var(--secondary);
}

.lastResultBtnText:hover {
  font-size: 1rem;
  font-family: var(--semiBold);
  color: var(--light-gray);
  padding: 0.25rem;
  width: 100%;
  height: 100%;
}
