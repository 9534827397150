.themesongButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.themesongButtonBtn {
  cursor: pointer;
}

.themesongButtonBtn img {
  width: 40px;
  height: 40px;
}
