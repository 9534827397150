.fullButton {
  display: flex;
  align-items: center;
  width: 18rem;
  height: 3rem;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
}

.fullButtonDisabled {
  display: flex;
  align-items: center;
  width: 18rem;
  height: 3rem;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

.fullButton:hover {
  background-color: var(--primary-hover);
}

.fullButtonDisabled {
  border: 1px solid var(--secondary)
}

.fullButton img,
.fullButtonDisabled img {
  width: 2rem;
  margin: 0 1rem;
}

.fullButton p,
.fullButtonDisabled p {
  font-family: var(--semiBold);
  color: #fff;
  font-size: 1.25rem;
  line-height: 2rem;
}
