.sopViewerPageContainer {
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
}

.sopViewerContents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sopViewerContentsTitle {
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: var(--semiBold);
  font-size: 1.5rem;
}

.sopViewerTableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  border: 1px solid var(--dark-gray);
}

.sopViewerTableContainerTabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--light-gray);
  height: 3.5rem;
}

.sopViewerTableContainerTabsLeftSide {
  width: 37.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid green; */
}
.sopViewerTableContainerTabsTabLeft {
  font-family: var(--semiBold);
  /* border: 1px solid yellow; */
  font-size: 1.25rem;
  width: 35%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 0.25rem;
}

.sopViewerTableContainerTabsTabLeftSelected {
  font-family: var(--semiBold);
  color: var(--primary);
  /* border: 1px solid yellow; */
  border-bottom: 2.5px solid var(--primary);
  font-size: 1.25rem;
  width: 25%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 0.25rem;
}
.sopViewerTableContainerTabsSpaceBetween {
  width: 15%;
}

.sopViewerTableContainerTabsRightSide {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid yellow; */
  font-family: var(--regular);
  font-size: 1rem;
  text-align: center;
}
.sopViewerTableContainerTabsTabRight {
  width: 22.5%;
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: #fff;
  padding: 0 0.25rem;
  cursor: pointer;
}

.sopViewerTableContainerTabsTabRight:last-child {
  margin-right: 0.5rem;
}

.sopViewerTableContainerTabsTabRightSopSelector {
  /* border: 1px solid gray; */
  width: 25%;
  display: flex;
  justify-content: center;
}

.sopViewerTableContainerTableTabsSops {
  width: 100%;
  display: flex;
  justify-content: start;
  /* border: 1px solid pink; */
  margin-top: 1rem;
}

.sopViewerTableContainerTableTabsSopsTab {
  /* padding-left: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--regular);
  width: 7.5%;
  height: 3.25rem;
  border-top: 1px solid var(--secondary);
  border-left: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
  margin-left: 1rem;
  text-align: center;
  cursor: pointer;
}

.sopViewerTableContainerTableTabsSopsTabSelected {
  /* padding-left: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--regular);
  width: 7.5%;
  height: 3.25rem;
  border-top: 1px solid var(--primary);
  border-left: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  color: #fff;
  background-color: var(--primary);
  margin-left: 1rem;
  text-align: center;
  cursor: pointer;
}

.sopViewerTableContainerTableTabsFilters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-gray);
  height: 2.5rem;
}

.sopViewerTableContainerTableTabsFiltersFilter:first-child {
  padding-left: 1rem;
}

.sopViewerTableContainerTableTabsFiltersFilter {
  font-family: var(--regular);
  text-align: center;
}

.sopViewerTableContainerTableTabsFiltersFilterExport {
  margin-right: 1rem;
  border: 1px solid var(--dark-gray);
  padding: 0.35rem 0.75rem;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.sopViewerTableContainerTableSection {
  width: 100%;
}

.sopViewerTableContainerTableSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary);
  width: 100%;
  height: 1.75rem;
}

.sopViewerTableContainerTableSectionHeaderName {
  width: 12.5%;
  font-family: var(--regular);
  color: #fff;
  font-size: 0.95rem;
}
.sopViewerTableContainerTableSectionHeaderName:first-child {
  padding-left: 0.5rem;
}
.sopViewerTableContainerTableSectionHeaderName:last-child {
  padding-right: 0.5rem;
}

.sopViewerTableContainerTableSectionTableBodyRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.75rem;
}

.sopViewerTableContainerTableSectionBodyRowInfo {
  width: 12.5%;
}
.sopViewerTableContainerTableSectionBodyRowInfo:first-child {
  padding-left: 0.5rem;
}
.sopViewerTableContainerTableSectionBodyRowInfo:last-child {
  padding-right: 0.5rem;
}

.sopViewwerToolTableHeader {
  color: #fff;
  font-family: var(--semiBold);
  background-color: var(--dark-gray);
  font-size: 1rem;
}
