.griContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);

}

.gridItemHeader {
  display: flex;

}

.flexContainerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexItemHeader {
  text-align: center;
  padding: 0.35rem 0;
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.15rem;
  color: var(--light-gray);
  background-color: var(--dark-gray);

}

.flexContainerRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
  padding: 0.35rem 0;

}