.backButton {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 4rem;
  cursor: pointer;
}

.backArrow {
  width: 1rem;
  margin-right: 0.5rem;
}

.backArrow img {
  width: 100%;
}

.backMessage a {
  text-decoration: none;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--primary);
}

.backMessage:hover {
  color: var(--primary-hover);
}
