.vinByUuIDContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vinByUuIDSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.vinByUuIDSectionTitle {
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.vinByUuIDSectionActionSection {
  /* margin-top: 1rem; */
  width: 65%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.flexContainerRowSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 100%;
  margin-top: 2.5rem;
}

.regionSelectionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 1.25%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 1.25%;
}

/* Search input field */
.flexContainerRowSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5vh;
}
.flexContainerRowSearch input {
  height: 2rem;
  font-size: 1.75rem;
  width: 40%;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.75px;
  padding-left: 1rem;
}

.actionBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  z-index: 999;
}

.actionBtn:hover {
  background-color: var(--light-gray);
}
.actionBtn img {
  width: 28px;
  height: 28px;
}

.actionBtnHighlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  margin-left: 1.5rem;
  z-index: 999;
  padding: 0.25rem;
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.actionBtnHighlight:hover {
  background-color: var(--light-gray);
}
.actionBtnHighlight img {
  width: 28px;
  height: 28px;
}

.actionBtnRefresh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  z-index: 999;
  padding: 0;
}
.actionBtnRefresh:hover {
  background-color: var(--light-gray);
}
.actionBtnRefresh img {
  width: 28px;
  height: 28px;
}

.displayBoxVinSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
  margin-top: 1.5rem;
}
.displayBoxVin {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.displayBoxVinText {
  font-family: var(--semiBold);
  font-size: 1.25rem;
  text-align: center;
  height: 2rem;
  margin: 0.5rem 0;
  width: 100%;
}

.vinAvailabilityBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;

  border: 1px solid var(--secondary);
  height: 3rem;
  width: 25%;
}
.vinAvailable {
  font-family: var(--regular);
  letter-spacing: 1px;
  font-size: 1.5rem;
  width: 75%;
}

.copyVIN {
  width: 10%;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copyVIN:hover {
  background-color: var(--light-gray);
}

.copyVIN img {
  width: 28px;
  height: 28px;
}

.flexContainerRowError {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  color: var(--primary);
  width: 100%;
  font-family: var(--regular);
  font-size: 1.2rem;
  padding: 0.35rem 0;
  height: 1.5rem;
  margin-top: -0.75rem;
  margin-bottom: 1rem;
}
.flexContainerRowSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  color: green;
  width: 100%;
  font-family: var(--regular);
  font-size: 1.2rem;
  padding: 0.35rem 0;
  height: 1.5rem;
  margin-top: -1rem;
}
