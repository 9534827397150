.flexContainerHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flexContainerRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  line-height: 1.5rem;
}
.flexContainerRowTitle {
  margin-top: -1rem;
  width: 100%;
  line-height: 4rem;
  font-size: 3rem;
  font-family: var(--regular);
  letter-spacing: 0.2;
  text-align: center;
  color: var(--light-gray);
  z-index: 999;
}

.flexItem:hover {
  background-color: var(--light-gray);
}

.leftSide,
.rightSide {
  width: 100%;
}
.leftSide {
  margin-top: -2rem;
  padding-left: 5rem;
}
.rightSide {
  margin-top: -2rem;
  padding-right: 5rem;
}

.previewContainer {
  position: relative;
  width: 100%;
  height: 75vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.previewContainer img {
  width: auto;
  height: 80%;
  opacity: 0.7;
  transform: scale(0.95);
  transition: transform 0.5s, opacity 0.5s;
}

.previewContainer img:hover {
  width: auto;
  height: 80%;
  opacity: 1;
  transform: scale(1);
  cursor: pointer;
}

.navigationBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  background-color: #fff;
  height: 4rem;
  width: 40%;
  top: 45%;
  left: 30%;
  cursor: pointer;
  border: 1px solid var(--primary);
  padding: 1px;
  z-index: 998;
}
.navigationBtn:hover {
  background-color: var(--dark-gray);
  border: 2px solid var(--primary);
  padding: 0;
}
.btnText {
  font-family: var(--regular);
  font-size: 1.5rem;
  color: var(--primary);
  z-index: 999;
}

.backendToolsContainer {
  width: 100%;
  margin-top: -3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.backendToolsTitle {
  width: 64.5%;
  font-family: var(--semiBold);
  color: #fff;
  line-height: 3rem;
  font-size: 1.75rem;
}

.backendToolsSection {
  margin-top: 1.5rem;
  width: 64.5%;
  display: flex;
  align-items: center;
  /* justify-content: start; */
}

.backendToolsTool {
  display: flex;
  width: 100%;
  height: 5rem;
  cursor: pointer;
  opacity: 0.7;
  transform: scale(0.95);
  transition: transform 0.5s, opacity 0.5s;
  margin-right: 2rem;
}

.backendToolsTool:hover {
  opacity: 1;
  transform: scale(1);
}

.backendToolsToolTitleContainer {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
}

.backendToolsToolTitle {
  width: 100%;
  font-size: 1.5rem;
  font-family: var(--regular);
  color: #fff;
  margin-left: 2rem;
}

.backendToolsToolThumbnail {
  height: 2rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.backendToolsToolThumbnail img {
  width: 3.5rem;
}
.backendToolsToolTransformers {
  display: flex;
  width: 100%;
  height: 5rem;
  cursor: pointer;
  opacity: 0.7;
  transform: scale(0.95);
  transition: transform 0.5s, opacity 0.5s;
  margin-right: 2rem;
}

.backendToolsToolTransformers:hover {
  opacity: 1;
  transform: scale(1);
}

.backendToolsToolThumbnailTransformers {
  height: 4rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.backendToolsToolThumbnailTransformers img {
  width: 5rem;
  height: auto;
  margin-top: -1rem;
}

/*   opacity: 0.7;
  transform: scale(0.95);
  transition: transform 0.5s, opacity 0.5s; */
