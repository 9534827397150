.progressDetails {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.progressDetailsTitle {
  background-color: var(--dark-gray);
  width: 100%;
  height: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
}

.progressDetailsTitle p {
  color: var(--light-gray);
  font-family: var(--semiBold);
  letter-spacing: 0.5px;
  font-size: 1.15rem;
  line-height: 2rem;
  margin-left: 1rem;
}
.progressDetailsStatus,
.progressDetailsResult,
.progressDetailsMessage,
.progressDetailsActionID {
  margin-left: 1rem;
}

.progressDetailsStatus,
.progressDetailsResult,
.progressDetailsMessage,
.progressDetailsActionID {
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.15rem;
}
.progressDetailsResult,
.progressDetailsMessage,
.progressDetailsActionID {
  margin-top: 1rem;
}

.progressDetailsResult p,
.progressDetailsMessage p,
.progressDetailsActionID p {
  font-family: var(--regular);
  margin: 0;
}

.scenarioException {
  width: 95%;
  display: flex;
  align-items: center;
  justify-self: start;
  color: var(--primary);
  font-family: var(--regular);
  margin-left: 1rem;
  margin-top: 1rem;
  height: 50%;
  overflow-y: scroll;
}
