.modalBck {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 40%;
  height: 40%;
  border: 1px solid var(--secondary);
  background-color: var(--light-gray);
  margin-left: 30%;
  margin-top: 12.5%;
}

.closeImg {
  height: 4%;
  align-self: flex-end;
  cursor: pointer;
  margin-right: 3%;
  margin-top: 3%;
}

.container {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

}

.title p {
  font-family: var(--semiBold);
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.message p {
  font-family: var(--regular);
  font-size: 1.1rem;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
}

.tests {
  font-family: var(--semiBold);
  font-size: 1.25rem;
}

.tests p {
  font-family: var(--regular);
  font-size: 1rem;
  line-height: 1.15rem;
}

.okayBtn {
  font-family: var(--bold);
  font-size: 1.5rem;
  align-self: center;
  border: 1px solid var(--primary);
  padding: 0.75% 2.5%;
  cursor: pointer;
  position: absolute;
  bottom: 5%;
}

.okayBtn:hover {
  background-color: var(--primary-hover);
  color: #fff;
}