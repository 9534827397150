.searchElement {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.searchControl {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
}

.searchBox {
  height: 0.75rem;
}

.searchBox input {
  font-family: var(--regular);
  background-color: inherit;
  /* border: 1px solid var(--secondary); */
  height: 100%;
  width: 100%;
  padding-bottom: 0.35rem;
  padding-left: 0.25rem;
  font-size: 1rem;
  z-index: 100;
}

.searchBox label {
  font-family: var(--semiBold);
  position: absolute;
  top: 0.35rem;
  left: 0.5rem;
  width: 100%;
  opacity: 0.5;
  z-index: 2;
}

.searchBox label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.searchContent {
  position: absolute;
  transition: all 0.3s ease;
  font-size: 1rem;
  opacity: 0.5;
  cursor: pointer;
  width: 1%;
  /* border: 1px solid green; */
}
.searchInputContainer {
  position: relative;
  width: 100%;
  height: 1.25rem;
  /* border: 1px solid gray; */
}

.searchBox input:focus + .searchLabel .searchContent,
.searchBox input:valid + .searchLabel .searchContent,
.searchInputContainer:focus-within .searchLabel .searchContent {
  transform: translateY(-100%);
  font-size: 1rem;
  opacity: 0.5;
}

.searchAction {
  cursor: pointer;
  margin-left: 3rem;
}

.searchAction img {
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--light-gray);
  width: 2rem;
}

.searchAction img:hover {
  padding: 0.425rem;
  border: 0.075rem inset var(--secondary);
}
