.testItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 1rem;
  cursor: pointer;

}

.testItemBordered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 1rem;
  border: 1px solid var(--primary);
  background-color: var(--light-gray);
  cursor: pointer;
}

.testItem:hover {
  background-color: var(--light-gray);
}

.testDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.testName {
  font-family: var(--semiBold);
}

.testID {
  font-family: var(--regular);
}

.testDetails img {
  cursor: pointer;
}