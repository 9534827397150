.griContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 200px);
  }

  .flexContainerHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7.5rem auto 2rem auto;
  }

  .flexContainerRow {
    /* position: absolute; */
    margin: 5rem auto;
    width: 100%;
    font-size: 1.75rem;
    font-family: var(--semiBold);
    text-align: center;
    opacity: 0.75;
  }

  .gridItemRow {
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
    font-size: 1.25rem;
    font-family: var(--regular);
    text-align: center;
  }