.certificateBundlesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certificateBundlesSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.displayBoxVinSectionError {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: var(--regular);
  color: var(--primary);
}

.certificateBundlesSectionTitle {
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.certificateBundlesSectionActionSection {
  margin-top: 2rem;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexContainerRowSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 100%;
  margin-top: 2.5rem;
}

.regionSelectionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 1.25%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 1.25%;
}

/* Search input field */
.flexContainerRowSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5vh;
}
.flexContainerRowSearch input {
  height: 3rem;
  font-size: 1.75rem;
  width: 50%;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.5px;
  padding-left: 1rem;
  margin-top: 1rem;
  /* margin-left: 2rem; */
}

/* Buttons */
/* Get BUNDLES Btn */
.actionBtnCertificateBundles {
  align-self: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 20%;
  margin-top: 5rem;
  font-family: var(--semiBold);
  text-align: center;
  background-color: var(--primary);
  color: #fff;
}
.actionBtnCertificateBundlesUnavailable {
  align-self: center;
  align-items: center;
  cursor: not-allowed;
  z-index: 999;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 20%;
  margin-top: 5rem;
  font-family: var(--semiBold);
  text-align: center;
  background-color: var(--dark-gray);
  color: #fff;
}
.actionBtnCertificateBundlesLoadingSpinner {
  align-self: center;
  align-items: center;
  text-align: center;
  z-index: 999;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 20%;
  margin-top: 5rem;
}

/* Checkbox section */
.certificateBundlesCheckboxSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  font-family: var(--regular);
}

/* Validation CHeckbox */
.validationTextBox {
  margin-top: 1rem;
  font-family: var(--regular);
  text-align: center;
  color: var(--primary);
  width: 100%;
  height: 2rem;
}
