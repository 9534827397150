.adminToolTraceTransformerPageContainer {
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
}

.adminToolTraceTransformerContents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.adminToolTraceTransformerContentsTitle {
  width: 100%;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: var(--semiBold);
  font-size: 1.5rem;
}

.adminToolTraceTransformerContentsInputFields {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.adminToolTraceTransformerContentsInputFieldsPhone {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.adminToolTraceTransformerContentsInputFieldsPhoneInfo {
  width: 25%;
}
.adminToolTraceTransformerContentsInputFieldsPhoneInfoText {
  font-family: var(--regular);
  font-size: 1.25rem;
}

.adminToolTraceTransformerContentsInputFieldsPhoneInfoDetails {
  font-family: var(--regular);
  font-size: 0.85rem;
  color: var(--secondary);
  width: 80%;
  line-height: 1.15rem;
  opacity: 0.9;
}
.adminToolTraceTransformerContentsInputFieldsPhoneSelectSectionCol {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.adminToolTraceTransformerContentsInputFieldsPhoneSelectSection {
  /* width: 75%; */
  margin: 1rem 0;
  display: flex;
}
.adminToolTraceTransformerContentsInputFieldsPhoneSelect {
  width: 33%;
}
.phoneInputComponentInfo {
  font-family: var(--regular);
  font-size: 1rem;
  margin-bottom: 0.25rem;
}
.phoneInputComponent {
  font-family: var(--regular);
}

.phoneInputComponentOnlyInput {
  font-family: var(--regular);
  font-size: 0.815rem;
  color: #222222;
  width: 10.5rem;
  border: 1px solid rgb(220, 218, 218);
  height: 2rem;
  border-radius: 4px;
  padding-left: 1rem;
}

.phoneInputAdd {
  height: 2.5rem;
  margin-top: 1.5rem;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3%;
}

.phoneInputAdd:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.phoneInputAdd img {
  width: 36px;
  height: 36px;
}
.adminToolTraceTransformerContentsInputFieldsDevice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.adminToolTraceTransformerContentsInputFieldsDeviceInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.adminToolTraceTransformerContentsInputFieldsDeviceSelect {
  width: 86.25%;
}

.adminToolTraceTransformerContentsInputFieldsEnvironment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.adminToolTraceTransformerContentsInputFieldsEnvironmentInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.adminToolTraceTransformerContentsInputFieldsEnvironmentSelect {
  width: 75%;
}

.adminToolTraceTransformerContentsInputFieldsBackend {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.adminToolTraceTransformerContentsInputFieldsBackendInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.adminToolTraceTransformerContentsInputFieldsBackendSelect {
  width: 75%;
}

.adminToolTraceTransformerContentsActionSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
  padding: 1rem 0;
}

.adminToolTraceTransformerContentsActionSectionInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.adminToolTraceTransformerContentsActionSectionSelect {
  width: 75%;
  display: flex;
}
.adminToolTraceTransformerContentsActionSectionSelectButtonGenerate {
  width: 75%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
}
.adminToolTraceTransformerContentsActionSectionSelectButtonGenerate:hover {
  background-color: var(--primary-hover);
}
.adminToolTraceTransformerContentsActionSectionSelectButtonGenerateUnavailable:hover {
  background-color: var(--dark-gray);
}
.adminToolTraceTransformerContentsActionSectionSelectButtonGenerateUnavailable {
  width: 75%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

.adminToolTraceTransformerContentsActionSectionSelectButtonUpload {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 2rem;
}

.adminToolTraceTransformerContentsActionSectionSelectButtonUpload:hover {
  background-color: var(--primary-hover);
}

.adminToolTraceTransformerContentsActionSectionSelectButtonUploadUnavailable {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 2rem;
}

.adminToolTraceTransformerContentsActionSectionSelectButtonDownload {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 2.5rem;
}

.adminToolTraceTransformerContentsActionSectionSelectButtonDownload:hover {
  background-color: var(--primary-hover);
}

.adminToolTraceTransformerContentsActionSectionSelectButtonDownloadUnavailable {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 2.5rem;
}

/* DISABLED download button for csv downloader component */
.adminToolTraceTransformerDownloadContentsActionSectionSelectButtonDisabledCSVDownloader {
  width: 85%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-top: -0.5rem;
  height: 1.5rem;
  /* margin-left: 3rem; */
}

.adminToolTraceTransformerContentsActionSectionSelectButton:hover {
  background-color: var(--primary-hover);
}

.adminToolTraceTransformerContentsActionSectionSelectButtonDisabled {
  width: 25%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

/* Tab related section */

.adminToolTraceTransformerTabsSection {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.adminToolTraceTransformerTab {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--light-gray);
  color: var(--secondary);
  padding: 0.5rem;
}

.adminToolTraceTransformerTabSelected {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  padding: 0.5rem;
}
.adminToolTraceTransformerTabsSection
  .adminToolTraceTransformerTab:nth-child(2) {
  margin-left: 1rem;
}

.adminToolTraceTransformerTabsSection
  .adminToolTraceTransformerTabSelected:nth-child(2) {
  margin-left: 1rem;
}

/* Upload tab related section */
.adminToolTraceTransformerUploadContentsInputFields {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.adminToolTraceTransformerUploadContentsActionSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  margin-top: 5rem;
}

.adminToolTraceTransformerUploadContentsActionSectionInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
  text-align: center;
}

.adminToolTraceTransformerUploadContentsActionSectionSelect {
  width: 30%;
  margin-left: 5rem;
  display: flex;
}

.adminToolTraceTransformerUploadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.adminToolTraceTransformerDownloadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 3rem;
  display: flex;
  align-items: center;
}
.adminToolTraceTransformerDownloadContentsActionSectionSelectButtonDisabled {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 3rem;
  display: flex;
  align-items: center;
}

.adminToolTraceTransformerUploadContentsActionSectionSelectButton:hover {
  background-color: var(--primary-hover);
}

.adminToolTraceTransformerUploadContentsActionSectionSelectButtonDisabled {
  width: 25%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

/* File Name Box */
.fileNameBox {
  width: 80%;
  text-align: center;
  font-family: var(--regular);
  margin-top: 0.5rem;
  margin-left: 20%;
  font-size: 1.25rem;
  color: var(--success);
}

.fileNameBoxManual {
  width: 100%;
  text-align: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--success);
  margin-top: -2rem;
}

/* ------Examples section --------- */
.adminToolTraceTransformerExampleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 25%;
  margin-left: 19.5%;
}

.adminToolTraceTransformerExampleText {
  font-family: var(--regular);
  color: var(--secondary);
  width: auto;
  font-size: 0.9rem;
  display: flex;
  line-height: 1.25rem;
}

.ccpExampleButton {
  font-family: var(--semiBold);
  color: var(--primary);
  width: auto;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 0.15rem;
}
