/*  */
/*  */
/* -----TABLE section----- */

.flexContainerRowTitle {
  width: 100%;
  line-height: 0rem;
  font-size: 1.5rem;
  font-family: var(--semiBold);
  text-align: center;
  opacity: 0.75;
}
.flexContainerRowWideTableTitle {
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.15rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.flexContainerRowWideTable {
  width: 100%;
  height: 10.5rem;
  display: flex;
  border-bottom: 1px solid var(--dark-gray);
}

.wideTableDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wideTableHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-gray);
  height: 3rem;
}

.wideTableHeaderTitle {
  text-align: center;
  border: 1px solid var(--secondary);
  height: 3rem;
  width: 100%;
  color: #fff;
  font-family: var(--semiBold);
}
.wideTableBody {
  overflow-y: scroll;
  width: 101.2%;
}

.wideTableRow {
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--light-gray);
}
.wideTableRowContents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  font-family: var(--regular);
  font-size: 0.8rem;
}
