.ccpGeneratorPageContainer {
  width: 100%;
  height: 87vh;
  display: flex;
  flex-direction: column;
}

.ccpGeneratorContents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ccpGeneratorContentsTitle {
  width: 100%;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: var(--semiBold);
  font-size: 1.5rem;
}

.ccpGeneratorContentsInputFields {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ccpGeneratorContentsInputFieldsPhone {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ccpGeneratorContentsInputFieldsPhoneInfo {
  width: 25%;
}
.ccpGeneratorContentsInputFieldsPhoneInfoText {
  font-family: var(--regular);
  font-size: 1.25rem;
}

.ccpGeneratorContentsInputFieldsPhoneInfoDetails {
  font-family: var(--regular);
  font-size: 0.85rem;
  color: var(--secondary);
  width: 80%;
  line-height: 1.15rem;
  opacity: 0.9;
}
.ccpGeneratorContentsInputFieldsPhoneSelectSectionCol {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.ccpGeneratorContentsInputFieldsPhoneSelectSection {
  /* width: 75%; */
  margin: 1rem 0;
  display: flex;
}
.ccpGeneratorContentsInputFieldsPhoneSelect {
  width: 33%;
}
.phoneInputComponentInfo {
  font-family: var(--regular);
  font-size: 1rem;
  margin-bottom: 0.25rem;
}
.phoneInputComponent {
  font-family: var(--regular);
}

.phoneInputComponentOnlyInput {
  font-family: var(--regular);
  font-size: 0.815rem;
  color: #222222;
  width: 10.5rem;
  border: 1px solid rgb(220, 218, 218);
  height: 2rem;
  border-radius: 4px;
  padding-left: 1rem;
}

.phoneInputAdd {
  height: 2.5rem;
  margin-top: 1.5rem;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3%;
}

.phoneInputAdd:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.phoneInputAdd img {
  width: 36px;
  height: 36px;
}
.ccpGeneratorContentsInputFieldsDevice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.ccpGeneratorContentsInputFieldsDeviceInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.ccpGeneratorContentsInputFieldsDeviceSelect {
  width: 86.25%;
}

.ccpGeneratorContentsInputFieldsEnvironment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.ccpGeneratorContentsInputFieldsEnvironmentInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.ccpGeneratorContentsInputFieldsEnvironmentSelect {
  width: 75%;
}

.ccpGeneratorContentsInputFieldsBackend {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.ccpGeneratorContentsInputFieldsBackendInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.ccpGeneratorContentsInputFieldsBackendSelect {
  width: 75%;
}

.ccpGeneratorContentsActionSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 3rem;
  padding: 1rem 0;
}

.ccpGeneratorContentsActionSectionInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
}

.ccpGeneratorContentsActionSectionSelect {
  width: 75%;
  display: flex;
}
.ccpGeneratorContentsActionSectionSelectButtonGenerate {
  width: 75%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
}
.ccpGeneratorContentsActionSectionSelectButtonGenerate:hover {
  background-color: var(--primary-hover);
}
.ccpGeneratorContentsActionSectionSelectButtonGenerateUnavailable:hover {
  background-color: var(--dark-gray);
}
.ccpGeneratorContentsActionSectionSelectButtonGenerateUnavailable {
  width: 75%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

.ccpGeneratorContentsActionSectionSelectButtonUpload {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 2rem;
}

.ccpGeneratorContentsActionSectionSelectButtonUpload:hover {
  background-color: var(--primary-hover);
}

.ccpGeneratorContentsActionSectionSelectButtonUploadUnavailable {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 2rem;
}

.ccpGeneratorContentsActionSectionSelectButtonDownload {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 2.5rem;
}

.ccpGeneratorContentsActionSectionSelectButtonDownload:hover {
  background-color: var(--primary-hover);
}

.ccpGeneratorContentsActionSectionSelectButtonDownloadUnavailable {
  width: 75%;
  margin-top: -0.5rem;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 2.5rem;
}

/* DISABLED download button for csv downloader component */
.ccpGeneratorDownloadContentsActionSectionSelectButtonDisabledCSVDownloader {
  width: 85%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-top: -0.5rem;
  height: 1.5rem;
  /* margin-left: 3rem; */
}

.ccpGeneratorContentsActionSectionSelectButton:hover {
  background-color: var(--primary-hover);
}

.ccpGeneratorContentsActionSectionSelectButtonDisabled {
  width: 25%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

/* Tab related section */

.ccpGeneratorTabsSection {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.ccpGeneratorTab {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--light-gray);
  color: var(--secondary);
  padding: 0.5rem;
}

.ccpGeneratorTabSelected {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  padding: 0.5rem;
}
.ccpGeneratorTabsSection .ccpGeneratorTab:nth-child(2) {
  margin-left: 1rem;
}

.ccpGeneratorTabsSection .ccpGeneratorTabSelected:nth-child(2) {
  margin-left: 1rem;
}

/* Upload tab related section */
.ccpGeneratorUploadContentsInputFields {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ccpGeneratorUploadContentsActionSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  margin-top: 5rem;
}

.ccpGeneratorUploadContentsActionSectionInfo {
  width: 25%;
  font-family: var(--regular);
  font-size: 1.25rem;
  text-align: center;
}

.ccpGeneratorUploadContentsActionSectionSelect {
  width: 25%;
  margin-left: 5rem;
  display: flex;
}

.ccpGeneratorUploadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
}
.ccpGeneratorDownloadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 3rem;
}
.ccpGeneratorDownloadContentsActionSectionSelectButtonDisabled {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 3rem;
}

.ccpGeneratorUploadContentsActionSectionSelectButton:hover {
  background-color: var(--primary-hover);
}

.ccpGeneratorUploadContentsActionSectionSelectButtonDisabled {
  width: 25%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

/* File Name Box */
.fileNameBox {
  width: 100%;
  text-align: center;
  font-family: var(--regular);
  margin-top: 1rem;
  font-size: 1.25rem;
  color: var(--success);
}

.fileNameBoxManual {
  width: 100%;
  text-align: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--success);
  margin-top: -2rem;
}

/* ------Examples section --------- */
.ccpExampleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 75%;
}

.ccpExampleText {
  font-family: var(--regular);
  width: auto;
  font-size: 0.9rem;
  display: flex;
  line-height: 1.5rem;
}

.ccpExampleButton {
  font-family: var(--semiBold);
  color: var(--primary);
  width: auto;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 0.15rem;
}
