.griContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);
}

.gridItemHeader {
  display: flex;

}

.flexContainerHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexItemHeader {
  text-align: center;
  padding: 0.35rem;
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.15rem;
  color: var(--light-gray);
  background-color: var(--dark-gray);
}

.gridItemRow {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--dark-gray);
}

.flexContainerRow {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
  background-color: var(--light-gray);
}

.flexItemRowLeft {
  width: 80%;
  font-size: 1.1rem;
  font-family: var(--semiBold);
  text-align: right;
  padding-right: 6px;
}

.flexItemRowRight {
  width: 120%;
  font-size: 1rem;
  font-family: var(--regular);
  padding-left: 6px;
}

.flexItemDetail {
  width: 100%;
}