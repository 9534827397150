.flexContainerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexContainerRow {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
  padding: 0.35rem 0;
}

.flexContainerRowSwimlane {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  margin-right: 1rem;
}

.flexContainerRowError {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  color: var(--primary);
  width: 100%;
  font-family: var(--regular);
  font-size: 1.2rem;
  padding: 0.35rem 0;
  height: 1.5rem;
  margin-top: -0.75rem;
}
.flexContainerRowSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  color: green;
  width: 100%;
  font-family: var(--regular);
  font-size: 1.2rem;
  padding: 0.35rem 0;
  height: 1.5rem;
  margin-top: -1.5rem;
}
.flexContainerRowSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 100%;
  margin-bottom: 0.25rem;
}
.flexContainerRowSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5vh;
}
.flexContainerRowSearch input {
  height: 2rem;
  font-size: 1.75rem;
  width: 35%;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.75px;
  padding-left: 1rem;
}

.actionBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  margin-left: 2rem;
  z-index: 999;
}

.actionBtn:hover {
  background-color: var(--light-gray);
}
.actionBtn img {
  width: 28px;
  height: 28px;
}

.actionBtnHighlight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  margin-left: 1.5rem;
  z-index: 999;
  padding: 0.25rem;
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 0, 28, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(213, 0, 28, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 0, 28, 0);
  }
}
.actionBtnHighlight:hover {
  background-color: var(--light-gray);
}
.actionBtnHighlight img {
  width: 28px;
  height: 28px;
}

.actionBtnRefresh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.05rem;
  height: 2.1rem;
  cursor: pointer;
  z-index: 999;
  padding: 0;
}
.actionBtnRefresh:hover {
  background-color: var(--light-gray);
}
.actionBtnRefresh img {
  width: 28px;
  height: 28px;
}

.regionSelectionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22.5%;
  margin-right: 1.25%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 22.5%;
  align-items: center;
  justify-content: center;
  margin-left: 1.25%;
}

.flexContainerAboveTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  width: 100%;
}
.aboveTableSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.aboveTableSectionTitle {
  font-size: 1.2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  width: 100%;
}
.aboveTableSectionText {
  font-size: 1.1rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  opacity: 0.5;
  width: 100%;
  line-height: 1rem;
}

/*  */
/*  */
/* -----TABLE section----- */
.flexContainerRowTable {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.tableDivSOP {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 13.5vh;
  width: 30%;
}

.tableDivCONNECTION {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 13.5vh;
  width: 65%;
  margin-left: 5%;
}

.tableTitle {
  width: 100%;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  border: 1px solid var(--dark-gray);
}
.tableTitleText {
  text-align: center;
  flex: 8;
  width: 100%;
  font-size: 1rem;
  line-height: 2.25rem;
  font-family: var(--semiBold);
}
.tableTitleIcon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.tableTitleIcon img {
  width: 24px;
  height: 24px;
}
.tableRow {
  width: 100%;
  border: 1px solid var(--dark-gray);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
}
.tableRowStatusTable {
  width: 100%;
  border: 1px solid var(--dark-gray);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
}
.tableRowError {
  width: 100%;
  border: 1px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  text-align: center;
}
.tableRowNoData {
  width: 100%;
  border: 1px solid var(--darkGray);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  text-align: center;
}
.tableRow:first-child {
  background-color: var(--light-gray);
}
.tableRowText {
  width: 100%;
  margin-left: 1rem;
  font-family: var(--regular);
  font-size: 0.85rem;
}
.tableRowRefresh {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 1.5rem;
  font-size: 0.75rem;
  font-family: var(--thin);
  color: var(--secondary);
  opacity: 0.6;
}
.tableRowTextStatus {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  font-family: var(--regular);
}

/* Connection LIVE table */

.tableRowStatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
}
.tableRowStatus img {
  width: 16px;
  height: 16px;
}
.tableRowStatusName {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  width: 100%;
  margin-left: 2rem;
  font-family: var(--regular);
}

.tableRowStatusTimestamp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-left: 2rem;
  font-family: var(--regular);
  font-family: var(--semiBold);
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1rem;
}
/*  */
/*  */
.flexContainerRowTitle {
  width: 100%;
  line-height: 0rem;
  font-size: 1.5rem;
  font-family: var(--semiBold);
  text-align: center;
  opacity: 0.75;
}
.flexContainerRowWideTableTitle {
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.15rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.flexContainerRowWideTable {
  width: 100%;
  height: 10.5rem;
  display: flex;
  border-bottom: 1px solid var(--dark-gray);
}

.doubleTableSet {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.downloadAllButton {
  display: flex;
  align-items: flex-start;
  height: 2.5rem;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}
.downloadAllBtnText {
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--primary);
  font-size: 1.25rem;
  font-family: var(--semiBold);
  color: var(--primary);
  background-color: var(--light-gray);
  cursor: pointer;
}
.downloadAllBtnText:hover {
  color: var(--light-gray);
  background-color: var(--primary);
}
.flexContainerRowDoubleTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tableDoubleDiv {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  height: 35vh;
  width: 47.5%;
  border: 1px solid var(--secondary);
}
.tableDoubleWidthDiv {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  height: 35vh;
  width: 100%;
  border: 1px solid var(--secondary);
}

.tableDoubleDivTitle {
  display: flex;
  position: relative;
  width: 95%;
  top: 0.5rem;
  left: 1rem;
}
.tableDoubleWidthDivTitle {
  display: flex;
  position: relative;
  width: 100%;
  top: 0.5rem;
  left: 1rem;
}
.tableDoubleDivTitleText {
  position: sticky;
  font-family: var(--semiBold);
  font-size: 1.25rem;
  width: 40%;
}
.tableDoubleWidthDivTitleText {
  position: sticky;
  font-family: var(--semiBold);
  font-size: 1.25rem;
  width: 40%;
}
.infoSnackBar {
  position: sticky;
  width: 45%;
  height: 2rem;
}
.infoSnackBarDoubleWidth {
  position: sticky;
  width: 50%;
  height: 2rem;
}
.copyIcon {
  position: sticky;
  width: 5.5%;
  height: 2rem;
  cursor: pointer;
}
.copyIcon img {
  width: 2rem;
}
.copyIconDoubleWidth {
  position: sticky;
  width: 2.75%;
  height: 2rem;
  cursor: pointer;
}
.copyIconDoubleWidth img {
  width: 2rem;
}

.infoIcon {
  position: sticky;
  width: 5.5%;
  height: 2rem;
  cursor: pointer;
}
.infoIcon img {
  margin-top: 0.25rem;
  width: 1.25rem;
}
.downloadIcon {
  position: sticky;
  width: 5.5%;
  height: 2rem;
  cursor: pointer;
  margin-left: 2.5%;
}
.downloadIconDoubleWidth {
  position: sticky;
  width: 5.5%;
  height: 2rem;
  cursor: pointer;
  margin-left: 1.25%;
}
.downloadIconDoubleWidth img {
  width: 2rem;
}
.downloadIcon img {
  width: 2rem;
}

.tableDoubleDivDescription {
  font-family: var(--regular);
  color: var(--secondary);
  font-size: 1rem;
  opacity: 0.8;
  margin-left: 1rem;
  width: 100%;
}
.tableDoubleDivJson {
  font-family: var(--regular);
  color: var(--secondary);
  font-size: 1rem;
  margin-left: 0.75rem;
  margin-top: 2rem;
  width: 97.5%;
  overflow-y: scroll;
  align-self: center;
}

.wideTableDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wideTableHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-gray);
  height: 3rem;
}

.wideTableHeaderTitle {
  text-align: center;
  border: 1px solid var(--secondary);
  height: 3rem;
  width: 100%;
  color: #fff;
  font-family: var(--semiBold);
}
.wideTableBody {
  overflow-y: scroll;
  width: 101.2%;
}
/* .wideTableBody::-webkit-scrollbar {
  display: none;
} */

.wideTableRow {
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--light-gray);
}
.wideTableRowContents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  font-family: var(--regular);
  font-size: 0.8rem;
}

/* VDS Check table */
/* Rows */
.vdsCheckTableRow {
  height: 1.2rem;
}
.vdsCheckTableHeaderRow {
  height: 1.2rem;
  background-color: var(--light-gray);
}

/* Cells */
.vdsCheckTableCell {
  /* height: 0.5rem; */
  font-family: var(--regular);
  border-right: 1px solid var(--light-gray);
}
.vdsCheckTableHeaderCell {
  font-family: var(--semiBold);
  /* height: 0.5rem; */
  border-right: 1px solid var(--light-gray);
}
.vdsCheckTableCellAvailable {
  font-family: var(--regular);
  /* height: 0.5rem; */
  border-right: 1px solid var(--light-gray);
  background-color: rgba(1, 138, 22, 0.2);
}
.vdsCheckTableCellUnavailable {
  font-family: var(--regular);
  /* height: 0.5rem; */
  border-right: 1px solid var(--light-gray);
  background-color: rgba(213, 0, 28, 0.2);
}

/* VDS Check Table Custom */
/* Container */
.vdsTableContainer {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Table div */
.vdsTable {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Table header */
.vdsTableHeadContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vdsTableHeadCellICTO,
.vdsTableHeadCellEnvironment,
.vdsTableHeadCellCheck,
.vdsTableHeadCellRegion,
.vdsTableHeadCellRegistered,
.vdsTableHeadCellURL {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--semiBold);
  height: 1.25rem;
  border: 0.5px solid var(--secondary);
  margin: 0;
  line-height: 1.25rem;
  padding: 0.5rem 0;
  background-color: var(--light-gray);
}

.vdsTableHeadCellICTO,
.vdsTableHeadCellEnvironment,
.vdsTableHeadCellCheck {
  width: 12.5%;
}
.vdsTableHeadCellRegion {
  width: 10%;
}
.vdsTableHeadCellURL {
  width: 25%;
}
.vdsTableHeadCellRegistered {
  width: 27.5%;
}

/* Table Body container */
.vdsTableBodyContainer {
  width: 100%;
  height: 100%;
}

.vdsTableBodyRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: var(--regular);
  text-align: center;
  line-height: 1.5rem;
  color: var(--secondary);
  font-size: 1rem;
  height: 100%;
  min-height: 2.5rem;

  border: 0.5px solid var(--dark-gray);
}

.vdsTableBodyCellICTO,
.vdsTableBodyCellEnvironment,
.vdsTableBodyCellCheck,
.vdsTableBodyCellRegion,
.vdsTableBodyCellRegistered,
.vdsTableBodyCellUrl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  /* border: 0.5px solid var(--dark-gray); */
}
.vdsTableBodyCellICTO,
.vdsTableBodyCellEnvironment,
.vdsTableBodyCellCheck {
  width: 12.5%;
  border-right: 0.5px solid var(--dark-gray);
}
.vdsTableBodyCellRegion {
  width: 10%;
  border-right: 0.5px solid var(--dark-gray);
}
.vdsTableBodyCellRegistered {
  width: 27.5%;
  border-right: 0.5px solid var(--dark-gray);
}
.vdsTableBodyCellUrl {
  width: 25%;
}

.vdsTableBodyCellAvailable {
  width: 12.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.25rem;
  /* border: 0.5px solid var(--dark-gray); */
  /* background-color: rgb(1, 138, 22, 0.2); */
  border-right: 0.5px solid var(--dark-gray);
  color: var(--success);
}
.vdsTableBodyCellUnavailable {
  width: 12.5%;
  height: 100%;
  display: flex;
  font-size: 1.25rem;
  border-right: 0.5px solid var(--dark-gray);

  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--error);

  /* border: 0.5px solid var(--dark-gray); */
  /* background-color: rgba(213, 0, 28, 0.2); */
}

/* registered - notRegistered */
.vdsTableBodyCellRegisteredDate {
  color: var(--success);
}

.vdsTableBodyCellNotRegisteredDate {
  color: var(--error);
}

/* CUBE section - pending manifest section */
.circleOfConsent {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

/* FIND containers for tables */
.findContainer {
  width: 99.25%;
  background-color: var(--light-gray);
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.findContainer input {
  height: 1.75;
  padding-left: 0.25rem;
  font-family: var(--regular);
  font-size: 1rem;
  width: 15rem;
}

.findContainer button {
  height: 1.75;
  font-family: var(--semiBold);
  margin: 0 0.5rem;
  width: 7.5rem;
  font-size: 1rem;
  background-color: var(--secondary);
  color: #fff;
}
.findContainer span {
  height: 1.75;
  font-family: var(--regular);
  margin: 0 0.75rem;
  font-size: 1rem;
  text-align: center;
  line-height: 1.75rem;
}
