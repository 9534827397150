.navSection {
  font-family: var(--semiBold);
  height: 10vh;
  border-bottom: 1px solid var(--divider-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.5rem 0 3.5rem;
}

.logo {
  cursor: pointer;
}
.currentPath {
  display: flex;
  align-items: center;
}

.nonActiveLink {
  text-decoration: none;
  color: var(--secondary);
  margin-left: 0.5rem;
  font-size: 1.25rem;
}

.activeLink {
  text-decoration: none;
  color: var(--secondary);
  margin-left: 0.5rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--primary);
}

.currentPath img {
  width: 1.5rem;
  height: 1.25rem;
  margin-left: 0.5rem;
}
