.spinner {
  display: inline-block;
  width: 3rem;
}
.spinner:after {
  content: " ";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 6px solid var(--secondary);
  border-color: var(--secondary) transparent var(--secondary) transparent;
  animation: spinner 2.5s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
