.accordionSection {
  height: 100%;
  overflow-y: scroll;
}

.acordionTitle {
  font-family: var(--bold);
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  margin: 1rem auto 0 auto;
}

.timestamp p {
  color: var(--secondary);
  font-family: var(--semiBold);
  opacity: 0.8;
  text-align: center;
}

.exception p {
  color: var(--primary);
  font-family: var(--semiBold);
  opacity: 0.8;
  text-align: center;
}

.accordionResults {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordionItem {
  width: 90%;
  cursor: pointer;
  border: 1px solid var(--dark-gray);
  margin-bottom: 0.75rem;
}

.accordionItem:hover {
  background-color: var(--light-gray);
}

.accordionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
}

.accordionName {
  width: 17.5rem;
  text-align: center;
}

.copyIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem;
}

.copyIcon img {
  width: 1.5rem;
}

.accordionArrow img {
  width: 1.75rem;
  margin-right: 1rem;
}

.progressImg img {
  width: 1.25rem;
  margin-left: 1rem;
}

.accordionContents {
  padding-top: 1rem;
  background-color: var(--light-gray);
}

.accordionContentsHidden {
  display: none;
}

.accordionTitle,
.accordionTestName,
.accordionTestID,
.accordionStatus,
.accordionMessage,
.accordionResult,
.accordionActionID {
  font-family: var(--semiBold);
  font-size: 1.15rem;
  line-height: 1.25rem;
  color: var(--secondary);
}

.accordionTestID,
.accordionTestName,
.accordionMessage,
.accordionStatus,
.accordionResult {
  margin: 0.5rem 1rem;
}

.accordionTitle p,
.accordionTestName p,
.accordionTestID p,
.accordionStatus p,
.accordionMessage p,
.accordionResult p,
.accordionActionID p {
  font-family: var(--regular);
  line-height: 1.35rem;
  font-size: 1rem;
}

.expand {
  background-color: var(--secodary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.expand p {
  border: 1px solid var(--secodary);
  text-align: center;
  width: 15rem;
  padding: 0.5rem;
  font-family: var(--semiBold);
  color: var(--light-gray);
  background-color: var(--dark-gray);
  font-size: 1.25rem;
}

.expand p:hover {
  background-color: var(--secondary);
}

/* Modal window */
.modalBck {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 75%;
  border: 1px solid var(--secondary);
  background-color: var(--light-gray);
  margin-left: 20%;
  margin-top: 7.5%;
  position: relative;
}

.closeImg {
  height: 1.25rem;
  align-self: flex-end;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 1rem;
}

.container {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  overflow-x: hidden;
}

.detailsContents {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 7.5rem;
}

.titleReq {
  margin-bottom: 1rem;
}

.titleReq p,
.titleRes p {
  font-family: var(--semiBold);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.messageReq p,
.messageRes p {
  font-family: var(--regular);
  font-size: 1rem;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
}

.buttons {
  display: flex;
  justify-content: center;
}

.okayBtn {
  position: absolute;
  left: 37.5%;
  bottom: 1rem;
  font-family: var(--bold);
  font-size: 1.5rem;
  width: 3.5rem;
  height: 2.25rem;
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.copyBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 1rem;
  height: 2.25rem;
  left: 50%;
  width: 3.5rem;
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.okayBtn:hover {
  background-color: var(--primary-hover);
  color: #fff;
}

.copyBtn:hover {
  background-color: var(--dark-gray);
}
