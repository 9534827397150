.snackBarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.snackBarContents {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--success);
}
.snackBarContentsNoOutline {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 0 2rem;
}

.snackBarTitle p {
  margin-left: 0.5rem;
  font-family: var(--regular);
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--success);
}
