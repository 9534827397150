.vinByUuIDContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vinByUuIDSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.displayBoxVinSectionError {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: var(--regular);
  color: var(--primary);
}

.vinByUuIDSectionTitle {
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

/* TABS selection section */
.pseudonymizerTabsSection {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  margin-top: 1rem;
}

.pseudonymizerTab {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--light-gray);
  color: var(--secondary);
  padding: 0.5rem;
}

.pseudonymizerTabSelected {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  padding: 0.5rem;
}
.pseudonymizerTabsSection .pseudonymizerTab:nth-child(2) {
  margin-left: 1rem;
}

.pseudonymizerTabsSection .pseudonymizerTabSelected:nth-child(2) {
  margin-left: 1rem;
}

.vinByUuIDSectionActionSection {
  margin-top: 2rem;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.regionSelectionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 1.25%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 1.25%;
}

/* Search audience input field */
.audienceInputSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  height: 3rem;
  width: 100%;
}

.audienceInputSectionText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 25%;
}

.audienceInputSectionText1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1rem;
  width: 130px;
  margin-right: 10px;
}

.actionBtnSearchInvisible {
  align-self: center;
  align-items: center;
  margin-left: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 22.5%;
}

.audienceSearchContainer {
  width: 100%;
}

.audienceSearchContainer input {
  font-size: 1.5rem;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.5px;
  width: 100%;
  padding-left: 1rem;
  /* margin-top: 1rem; */
}

/* Pseudo VIN section */

.pseudoVINInputSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  height: 3rem;
  width: 100%;
}

.pseudoVINInputSectionText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 25%;
}

.pseudoVinSearchContainer {
  width: 100%;
}

.pseudoVinSearchContainer input {
  font-size: 1.5rem;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.5px;
  width: 100%;
  padding-left: 1rem;
}

.actionBtnSearchForPseudoVin {
  align-self: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  margin-left: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 22.5%;
  font-family: var(--semiBold);
  text-align: center;
  background-color: var(--primary);
  color: #fff;
}

.actionBtnSearchForPseudoVin:hover {
  background-color: var(--primary-hover);
  color: var(--light-gray);
}
/*  */
/* Displayed result */
.displayedResultSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  margin-top: 1rem;
}
.displayedResult {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.displayedResultText {
  font-family: var(--semiBold);
  font-size: 1.25rem;
  text-align: center;
  height: 2rem;
  margin: 0.5rem 0;
  width: 100%;
}

.resultAvailabilityBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border: 1px solid var(--secondary);
  height: 3rem;
  width: 49%;
}
.resultAvailable {
  font-family: var(--regular);
  letter-spacing: 1px;
  font-size: 1rem;
  width: 100%;
}

.copyVIN {
  width: 10%;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copyVIN:hover {
  background-color: var(--light-gray);
}

.copyVIN img {
  width: 28px;
  height: 28px;
}

/* Horizontal dividers in page */
.horizontalDividerContainer {
  width: 100%;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
/* Horizontal dividers in between sections */
.horizontalDividerContainerSection {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Invalid entered value warning box */
.invalidValueWarning {
  width: 100%;
  height: 1.5rem;
  text-align: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--primary);
}

/* Upload tab related section */
.pseudonymizerUploadContentsInputFields {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pseudonymizerUploadContentsActionSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  margin-top: 1rem;
}

.pseudonymizerUploadContentsActionSectionInfo {
  width: 50%;
  font-family: var(--regular);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.pseudonymizerUploadContentsActionSectionSelect {
  width: 50%;
  display: flex;
}

.pseudonymizerUploadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
}
.pseudonymizerDownloadContentsActionSectionSelectButton {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  margin-left: 3rem;
}
.pseudonymizerDownloadContentsActionSectionSelectButtonDisabled {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
  margin-left: 3rem;
}

.pseudonymizerUploadContentsActionSectionSelectButton:hover {
  background-color: var(--primary-hover);
}

.pseudonymizerUploadContentsActionSectionSelectButtonDisabled {
  width: 25%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-family: var(--semiBold);
  color: #fff;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  cursor: not-allowed;
}

/* Audience related section in upload tab */
.audienceInputSectionUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  height: 3rem;
  width: 75%;
}

.audienceInputSectionTextUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 50%;
}
.actionBtnSearchInvisibleUpload {
  align-self: center;
  align-items: center;
  margin-left: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 22.5%;
}

.audienceSearchContainerUpload {
  width: 100%;
}

.audienceSearchContainerUpload input {
  font-size: 1.5rem;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.5px;
  width: 100%;
  padding-left: 1rem;
  /* margin-top: 1rem; */
}

.horizontalDividerContainerUpload {
  width: 100%;
  margin-top: 2rem;
  /* margin-bottom: 1rem; */
}

.pseudonymizerUploadContentsActionSectionInfo2 {
  width: 50%;
  font-family: var(--regular);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.uploadIsLoadingBox {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadIsLoadingBoxText {
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--success);
}

/* Custom spinner */
.spinner {
  display: inline-block;
  width: 3rem;
}
.spinner:after {
  content: " ";
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 6px solid var(--success);
  border-color: var(--success) transparent var(--success) transparent;
  animation: spinner 3.5s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* File name box */
.fileNameBox {
  margin-top: 1rem;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--success);
}

/* Too many entries message */

.tooManyEntriesErrorMessage {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--primary);
}
