.actionSection {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 4.5%;
}

.infoSnackBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.griContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);
}

.gridItemHeader {
  display: flex;
}

.flexContainerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexItemHeader {
  width: 100%;
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin: 1.5rem auto;
  text-align: center;
  opacity: 0.7;
}

.gridItemRow {
  display: flex;
  width: 100%;
}
/* 
.flexContainerRow {
  display: flex;
  width: 100%;
  line-height: 1.5rem;
  padding: 0.35rem 0;
} */

.flexContainerRow {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
  padding: 0.35rem 0;
}

.flexContainerRowTitle {
  /* position: absolute; */
  width: 100%;
  line-height: 0rem;
  font-size: 1.5rem;
  font-family: var(--semiBold);
  text-align: center;
  opacity: 0.75;
}
