.vinByUuIDContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vinByUuIDSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.displayBoxVinSectionError {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: var(--regular);
  color: var(--primary);
}

.vinByUuIDSectionTitle {
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.vinByUuIDSectionActionSection {
  margin-top: 2rem;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexContainerRowSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.25rem;
  width: 100%;
  margin-top: 2.5rem;
}

.regionSelectionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 1.25%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 1.25%;
}

/* Search input field */
.flexContainerRowSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120%;
  height: 5vh;
}
.flexContainerRowSearch input {
  height: 3rem;
  font-size: 1.75rem;
  width: 100%;
  font-family: var(--regular);
  color: var(--secondary);
  opacity: 0.75;
  letter-spacing: 0.5px;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
}

/* Buttons */
/* Get VIN Btn */
.actionBtnSearchUUIDToVIN {
  align-self: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  margin-left: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 30%;
  font-family: var(--semiBold);
  text-align: center;
  background-color: var(--primary);
  color: #fff;
}
/* VIN related */
.displayBoxVinSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
  margin-top: 1.5rem;
}
.displayBoxVin {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.displayBoxVinText {
  font-family: var(--semiBold);
  font-size: 1.25rem;
  text-align: center;
  color: var(--secondary);
  height: 2rem;
  margin: 0.5rem 0;
  font-family: var(--regular);
  width: 100%;
}

.vinAvailabilityBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--dark-gray);
  height: 3rem;
  width: 45%;
  color: var(--secondary);
  font-family: var(--regular);
}
.vinAvailable {
  font-family: var(--regular);
  letter-spacing: 1px;
  font-size: 1.5rem;
  width: 75%;
}

.copyVIN {
  width: 10%;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copyVIN:hover {
  background-color: var(--light-gray);
}

.copyVIN img {
  width: 28px;
  height: 28px;
}

/* UUID related */

.displayBoxUUIDSection {
  width: 120%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
  margin-top: 1.5rem;
}
.displayBoxUUID {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.displayBoxUUIDText {
  font-family: var(--semiBold);
  font-size: 1.25rem;
  text-align: center;
  color: var(--secondary);
  height: 2rem;
  margin: 0.5rem 0;
  font-family: var(--regular);
  width: 100%;
}

.uuidAvailabilityBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--dark-gray);
  height: 3rem;
  width: 45%;
  color: var(--secondary);
  font-family: var(--regular);
}
.uuidAvailable {
  font-family: var(--regular);
  letter-spacing: 1px;
  font-size: 1.5rem;
  width: 75%;
}

.copyUUID {
  width: 10%;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copyUUID:hover {
  background-color: var(--light-gray);
}

.copyUUID img {
  width: 28px;
  height: 28px;
}

/* vin to uuid section */
.vinToUUIDSection {
  margin-top: 2rem;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vinByUuIDSectionTitleVinToUuid {
  font-family: var(--semiBold);
  font-size: 1.5rem;
  margin-top: 4rem;
}
