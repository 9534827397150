.pageBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.backgroundImg {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  filter: brightness(50%);
}
.titleSection {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  line-height: 5rem;
  font-size: 3.5rem;
  color: var(--light-gray);
  opacity: 0.85;
  font-family: var(--thin);
}

.pageContainer {
  z-index: 1000;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(100%);
}
.subTitleDiv {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subTitle {
  position: absolute;
  bottom: 20%;
  line-height: 5rem;
  font-size: 2rem;
  color: var(--light-gray);
  opacity: 0.9;
  font-family: var(--regular);
  margin-bottom: 0.5rem;
}

.backgroundEnvAndRegion {
  width: 40%;
  padding: 0.5rem 0;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(150, 152, 154, 0.5);
}
.regionAndEnvSection {
  position: absolute;
  bottom: 4.5%;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.regionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
}

.environmentSection {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
  justify-content: center;
}

.searchElement {
  position: absolute;
  bottom: 12.5%;
  left: 30vw;
  width: 40vw;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.searchControl {
  position: relative;
  width: 100%;
}

.searchBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
}

.searchBox input {
  font-family: var(--regular);
  color: var(--secondary);
  background-color: var(--light-gray);
  height: 100%;
  width: 100%;
  padding: 0.75rem;
  padding-left: 1.5rem;
  opacity: 0.75;
  font-size: 2rem;
  letter-spacing: 0.75px;
  z-index: 100;
}

.searchBox label {
  font-family: var(--semiBold);
  position: absolute;
  top: 0.35rem;
  left: 0.5rem;
  width: 100%;
  opacity: 0.5;
  z-index: 2;
}

.searchBox label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.searchAction {
  cursor: pointer;
  margin-left: 3rem;
}

.arrowBtn {
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(180deg);
}

.actionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  background-color: var(--light-gray);
  border-right: 2px solid rgb(104, 104, 104);
  border-bottom: 2px solid rgb(104, 104, 104);
  border-top: 2px solid rgb(47, 46, 46);
  opacity: 0.75;
}

.actionBtn:hover {
  background-color: rgb(104, 104, 104);
}
