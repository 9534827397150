.snackBarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.snackBarContents {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary);
    position: relative;

}


.closeImg {
    position: absolute;
    right: 0;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 75%;
    z-index: 999;
}

.closeImg:hover{
    background-color: var(--light-gray);
}

.closeImg img{
    width: 1rem;
}
.closeImg img:hover {
    background-color: var(--light-gray);
}

.snackBarTitle {
    display: flex;
    align-items: center;
}

.body {
    display: flex;
    padding: 0.5rem 3rem 0.5rem 2rem;
}

.snackBarTitle p{
font-family: var(--semiBold);
 font-weight: bold;
 font-size: 1rem;
 line-height: 1rem;
}

.snackBarTitle span {
margin-left: 0.25rem;
font-size: 1rem;
font-family: var(--regular);
color: var(--secondary);
opacity: 0.8;
line-height: 1rem;


}

.snackBarMessage {
    display: flex;
    align-items: center;  
    margin-left: 0.5rem;  
}

.snackBarMessage p{
    font-family: var(--semiBold);
    font-size: 1rem;
    line-height: 2rem;

}

.snackBarMessage span {
    font-family: var(--regular);
    margin-left: 0.25rem;
    font-size: 1rem;
    color: var(--secondary);
    opacity: 0.8;
    line-height: 1rem;

}