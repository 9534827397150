/* .homePage {
  height: 87.5vh;
} */

.searchSection {
  display: flex;
  justify-content: space-between;
}

.gridItemRow {
  display: flex;
  margin-top: 2rem;
}

.results {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  width: 100%;
  z-index: 1;
}

.actionSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5%;
}

.griContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);
}

.gridItemHeader {
  display: flex;
}

.flexContainerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flexItemHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flexContainerRow {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
  padding: 0.35rem 0;
}

.flexContainerRowTitle {
  /* position: absolute; */
  width: 100%;
  line-height: 0rem;
  font-size: 1.5rem;
  font-family: var(--semiBold);
  text-align: center;
  opacity: 0.75;
}
